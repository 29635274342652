import React from 'react'
import Layout from '../layouts/Layout'
import OverviewBanner from '../components/OverviewBanner'
import EarnCredentialOverview from '../components/EarnCredentialOverview'
import NewsOffers from '../components/NewsOffers'
import VerifyCredential from '../components/VerifyCredential'
import Credentials from '../components/Credentials'
import APCredentials from '../components/APCredentials'
import WhatIs from '../components/WhatIs'
import RoleMenu from '../components/RoleMenu'
import JoinCommunity from '../components/JoinCommunity';
import EarnCredential from '../components/EarnCredential';
import MaintenanceExams from '../components/MaintenanceExams';

export const OverviewTemplate = context => {
  const {
    pageContext: {
      data,
      data: {
        slug,
        title,
        hideRolesMenu,
        htmlContent,
        credentialsTitle,
        credentialsList,
        categorizedCredentials
      },
      lang
    }
  } = context

  const CredentialsSection = () => {
    if (credentialsList && credentialsList.length > 0) {
      return (
        <>
          <div className="cert-site_title slds-p-top--large"><h1>{credentialsTitle}</h1></div>
          <Credentials names={credentialsList} lang={lang} />
        </>
      )
    } else {
      return null;
    }
  }
  const APCredentialsSection = () => {
    if (credentialsList && credentialsList.length > 0) {
      return (
        <>
          <div className="cert-site_title slds-p-top--large"><h1>{credentialsTitle}</h1></div>
          <APCredentials names={credentialsList} lang={lang} />
        </>
      )
    } else {
      return null;
    }
  }

  return (
    <Layout title={title} lang={lang} slug={slug}>
      {/* Overview page Banner */}
      <OverviewBanner currentSlug={slug} lang={lang} />

      <div className="cert-site-bg--trailhead-icons slds-p-bottom--x-large">
        {/* ROLE MENU */}
        {!hideRolesMenu && (
          <RoleMenu currentSlug={slug} lang={lang} />
        )}

        {/* HTML CONTENT */}
        <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>

        {/* List of credentials */}
        {slug === 'accreditedprofessionaloverview' ? <APCredentialsSection /> : <CredentialsSection />}

        {/* Categorized Credentials */}
        {categorizedCredentials && categorizedCredentials.length > 0 && (
          <>
            {categorizedCredentials.map((category, index) => (
              <div key={`category-${index}`}>
                <div className="cert-site_title slds-p-top--large"><h1>{category.title}</h1></div>
                <Credentials names={category.credentials} lang={lang} />
              </div>
            ))}
          </>
        )}

        {/* What is .... */}
        {slug !== 'accreditedprofessionaloverview' && (
          <WhatIs item={data} />
        )}
      </div>

      {slug !== 'accreditedprofessionaloverview' && (
        <>
          <div className="slds-p-top--xx-large slds-p-horizontal_medium">
            {/* Earn your Credential */}
            <EarnCredentialOverview lang={lang} data={[]} />
          </div>

          {/* News and Offers */}
          <NewsOffers lang={lang} />

          {/* Verify a Credential */}
          <VerifyCredential lang={lang} />
        </>
      )}

      {slug === 'accreditedprofessionaloverview' && (
        <>
          <div className="slds-p-top--large slds-p-bottom--x-large">
            <JoinCommunity lang={lang} slug={slug} />
          </div>
          <div className="cert-site-bg-white slds-p-bottom--x-large">
            <div className="slds-m-top--x-large">
              <EarnCredential data={[]} lang={lang} />
            </div>
          </div>
          <div className="slds-grid slds-wrap slds-grid--pull-padded-medium">
            <div className="slds-size--1-of-1 architect-table-container">
              <MaintenanceExams category={1} lang={lang} slug={slug} />
            </div>
          </div>
        </>
      )}


    </Layout>
  )
}

export default OverviewTemplate
