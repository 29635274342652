import React from 'react';
//import PropTypes from 'prop-types';
import Hyphenated from 'react-hyphen';
import I18n from '../utils/I18n';
import Translation from '../utils/Translation';
import credentials from "../../data/certifications.json";
import superbadges from "../../data/superbadges.json";

const Credentials = ({ names, style, lang }) => {
  const i18n = new I18n({ lang });
  const toSlug = (s) => { return s.replace(/_/g, '') };
  const styleClass = style ? `credentials-card_${style}` : '';

  let cardItems = [];

  const creds = Translation.getItems(credentials, names, 'slug', lang);
  const supBadgesEn = Translation.getItems(superbadges, names, 'name', 'en');
  const supBadges = Translation.getItems(superbadges, names, 'name', lang);

  names.map((n, index) => {
    const credIt = creds.filter(it => it.slug === n.toSlug());
    const supIt = supBadges.filter(it => it.name === n);
    const item = credIt.length > 0 ? credIt[0] : null;
    if (item) {
      cardItems.push({
        type: 'Certification',
        typeText: i18n.text('credentialCard_certification'),
        cta_url: item.cardCtaLink ? `/${lang}/${item.cardCtaLink}` : i18n.text('credentialCard_scheduleNowLink'),
        cta_txt: item.cardCtaLabel ? item.cardCtaLabel : i18n.text('credentialCard_scheduleNow'),
        cta_txt_now: item.cardCtaLabel ? item.cardCtaLabel : i18n.text('credentialCard_Coming Soon'),
        itemTitle: item.title,
        itemUrl: item.examUrl,
        imageUrl: item.cardImg,
        description: item.cardText
      });
    }
  });

  cardItems.sort((a, b) => a.itemTitle)

  cardItems.sort((a, b) => {
    if (a.itemTitle && b.itemTitle) {
      const nameA = a.itemTitle.toLowerCase();
      const nameB = b.itemTitle.toLowerCase();

      return nameA < nameB ? -1 : (nameA > nameB ? 1 : 0);
    }

    return 0;
  })

  return (
    <div className="slds-container--center slds-container--x-large slds-p-top--small">
      <div className="slds-grid slds-wrap slds-grid--align-center slds-grid--pull-padded slds-grid--vertical-stretch">
        {cardItems.map((card, index) => (
          <div className="slds-size--1-of-1 slds-large-size--1-of-3 slds-p-horizontal--medium slds-p-vertical--medium" key={index}>
            <div className={`credentials-card ${styleClass}`}>
              <img className="credentials-card_image" alt="Logo for Salesforce Certified Professional" src={card.imageUrl}></img>
              <div className="F(r)">
                {card.type == 'Certification' && (
                  <div className="slds-text-align--right">
                    <a className="cert-btn--grey slds-m-top--x-small CTA" target="_blank" href={card.cta_url} >
                      <Hyphenated>{card.cta_txt}</Hyphenated>
                    </a>
                  </div>
                )}
              </div>
              {/* <div className="credentials-card_type">{card.typeText}</div> */}
              <div className="credentials-card_title">
                <a href={card.itemUrl} target="_blank" dangerouslySetInnerHTML={{ __html: card.itemTitle }}></a>
              </div>
              <div className="credentials-card_description">{card.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Credentials;
